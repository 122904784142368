.nav-outer {
	/*position: relative;
	z-index: 5;*/
}

.nav-top {
	background: white;
	height: 65px;
}

.nav-top-header {
	margin-right: 5%;
	font-family: Montserrat;
	font-size: 18px;
	font-size: 14px;
	color: #999;
	color: #c97922;
	/*font-weight: bold;*/
	letter-spacing: 1.8px;
}

.navbar {
	/*position: fixed;*/
    width: 100%;
    min-width: 600px;
    background: white;
    background: #2f3338;
    z-index: 4;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

.navbar-inner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 1400px;
	height: 65px;
	margin: 0 auto;
	/*background: white;*/
}

.navbar-inner.links {
	height: 55px;
}

.menu-button {
	height: 30px;
	width: 65px;
	margin-left: 10px;
	padding: 0;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	text-align: center;
	font-size: 13px;	
	box-shadow: none;
	cursor: pointer;
}

.navbar-links {
	padding-right: 10px;
}

.navbar-link {
	align-self: center;
	position: relative;
	margin: 0 8px;
	margin: 0 15px;
	font-size: 14px;
	font-size: 16px;
	font-weight: 500;
	color: #999;
	color: white;
	text-decoration: none;
	cursor: pointer;
}

.nav-logo {
	height: 75%;
    margin: 0 auto 0 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.nav-logo img {
	height: 40px;
    margin-top: 4.5%;
}


@media screen and (min-width: 1000px) {

	.nav-top-header {
		margin-right: 10%;
		font-size: 15px;
		letter-spacing: 3px;
	}


}


