
.faq-holder {
	width: 92%;
	max-width: 800px;
	margin: 40px auto 0 auto;
	padding-bottom: 30px;
	text-align: left;
}

.faq-group-header {
	margin-bottom: 28px;
	font-size: 28px;
	color: #f46065;
	color: #c97922;
	font-weight: 100;
}

.faq-section {
	position: relative;
    width: 97%;
    margin-top: 10px;
    padding: 0 3%;
    font-size: 16px;
    color: #333;
    color: #444951;
    border-radius: 10px;
}

.faq-section-left {
	position: absolute;
	left: 0;
	top: 10px;
	height: 60px;
	border-left: 3px solid #ffb300;
	border-left: 3px solid #22cca7;
	border-left: 3px solid #c97922;
}

.faq-section-header {
	display: flex;
	align-content: center;
	padding: 10px 0;
}

.faq-section-title {
	font-size: 20px;
	/*font-weight: bold;*/
}

.faq-section-body {
	width: 100%;
	padding-bottom: 30px;
	font-size: 15px;
	font-weight: 100;
	line-height: 22px;
	margin-top: 5px;
}

.faq-section-body ul {
	margin-bottom: 0;
}

.faq-section-body a {
	text-decoration: none;
	font-weight: bold;
	color: #960939;
	cursor: pointer;
}

.faq-other-questions {
	margin-top: 20px;
	text-align: center;
	font-size: 20px;
	color: #999;
}

.faq-other-questions i {
	margin-top: 10px;
	font-size: 40px;
	color: #4b79c1;
	cursor: pointer;
}

.divider {
	height: 1px;
	width: 100%;
	background: #ddd;
	margin-top: 80px;
}



