
.terms-holder {
	position: relative;
	width: 92%;
	max-width: 800px;
	margin: 40px auto 0 auto;
	padding-bottom: 50px;
	text-align: left;
	font-size: 15px;
	font-weight: lighter;
	color: #333;
	color: #444951;
}

.terms-holder > hr {
	border-top: 1px solid #eee;
}

.terms-disclaimer {
	/*width: calc(102% - 30px);
	position: relative;
	left: -1%;*/
	margin: 25px 0 20px 0;
	padding: 10px 15px;
	background: #e8eef7;
	font-size: 15px;
/*	color: #000;*/
	border-radius: 5px
}

.terms-disclaimer > .terms-subheader {
	padding-top: 5px;
}

.terms-header {
	margin-bottom: 28px;
	color: #f46065;
	color: #c97922;
	font-size: 28px;
}

.terms-type-header {
	margin-bottom: 28px;
	color: #f46065;
	font-size: 18px;
}

.terms-subheader {
	padding: 20px 0 0 0;
	font-size: 20px;
	font-weight: normal;
	color: #000;
	color: #444951;
}

.terms-holder p {
	line-height: 22px;
}

.terms-link {
	font-weight: bold;
	font-weight: normal;
	color: #960939;
	color: #0099e5;
	color: #22cca7;
	text-decoration: underline;
	cursor: pointer;
}

.rapid-disclaimer {
	font-size: 14px;
	color: #aaa;
	display: block;
	margin-top: 40px;
}
/*div.stripe-link {
	margin: 10px 0 25px 0;
}*/


