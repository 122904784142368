
.docs {
	position: relative;
	/*width: 100%;*/
	padding-bottom: 40px;
	padding-top: 20px;
	/*display: flex;
	height: 100%;*/
	width: 100%;
	min-width: 600px;
}

.docs-sidebar {
	display: none;
	position: fixed;
	top: 0;
	/*position: relative;*/
	/*position: absolute;*/
	height: 100%;
	width: 250px;
	width: 30%;
	background: #fafafa;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	font-size: 16px;
	z-index: 10;
}

.docs-sidebar-inner {
	/*display: flex;
	flex-direction: column;*/
	width: 100%;
	position: relative;
	left: 0px;
	width: 180px;
	margin: 20px 20px 0 auto;
	text-align: left;
}


.docs img {
	position: absolute;
	right: 20px;
	/*text-align: right;*/
	height: 40px;
	/*margin: 0 auto;*/
	margin-left: auto;
	cursor: pointer;
}


.sidebar-links-holder {
	margin-left: 15px;
	margin-top: 70px;
}


.sidebar-links-holder > div {
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 0.5px;
}

.docs-sidebar-inner > .docs-sidebar-header {
	position: relative;
	left: -5px;
	margin-top: 75px;
	margin-bottom: 15px;
	font-size: 25px;
	color: #3a506d;
	font-weight: bold;
	filter: brightness(170%);
}

.docs-sidebar ul {
	list-style: none;
	padding-left: 15px;
	margin: 0;
	font-weight: normal;
	opacity: 0.85;
	letter-spacing: 0.5px;
}

.docs-sidebar li {
	list-style: none;
	margin-top: 5px;
	font-weight: 100;
}

.docs-link {
	text-decoration: none;
	color: #37444f;
}

.docs-body {
	position: relative;
	/*left: 10px;*/
	width: 95%;
	min-width: 550px;
	top: 0;
	margin: 0 auto;
	margin-top: 50px;
	padding-bottom: 30px;
	text-align: left;
	font-size: 14px;	
}

.anchor {
    display: block;
    position: relative;
    top: -7px;
    visibility: hidden;
}

#initial.anchor {
	top: -100px;
}

#endpoints.anchor {
	top: -15px;
}

.initial-setup {
	margin-bottom: 30px;
	margin-top: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.docs-api-key {
	display: flex;
	flex-direction: column;
	width: 530px;
	margin: 0 auto;
}

.generic-dark-space {
	padding: 15px;
	background: #333;
	text-align: center;
	font-size: 15px;
	color: #aaa;
	border-radius: 5px;
}

.header-attribute {
	margin-right: 10px;
	color: #c1ef65;
	color: #d6ff84;
}

.docs-show-api-key {
	align-self: flex-end;
	width: 70px;
	height: 25px;
	margin-top: 5px;
	background: #0ea4f4;
	font-size: 11px;
	font-weight: bold;
	line-height: 24px;
	box-shadow: none;
}

.docs-show-api-key:disabled {
	visibility: hidden;
}

.docs-body-inner-header {
	margin-bottom: 40px;
}

.docs-body p {
	/*font-family: "Source Sans Pro";*/
	font-size: 15px;
	font-weight: normal;
	line-height: 1.3;
}

.download-link {
	color: #4e84c9;
	cursor: pointer;
}

.docs-body p em {
	padding: 1px 4px;
	background: #f2f2f2;
	font-style: normal;
	color: #aa3330;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.docs-body p em.endpoint {
	color: #064568;
	font-weight: bold;
}

.league-list {
	display: flex;
	justify-content: space-between;
	position: relative;
	left: 0;
	width: 65%;
	margin: 0 auto 30px auto;
	padding: 15px 25px;
	background: #f2f2f2;
	border: 1px solid #ddd;
	border-radius: 5px;
}

.league-list > div {
	list-style: none;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

.api-section {
	padding: 10px 0;
	font-size: 25px;
	color: #999;
	border-top: 1px solid #ccc;
}

.api-section-overview {
	margin: 20px 0 30px 0;
	padding: 15px;
	background: #e8eef7;
	font-size: 15px;
	color: #000;
	border-radius: 5px
}

.api-section-restricted {
	margin-top: 12px;
	font-style: italic;
	color: #617daa;
	color: #7b8dad;
	margin-bottom: -2px;
}

.api-arg {
	display: flex;
	width: 100%;
	margin: 20px 0 30px 0;
	font-size: 16px;
}

.api-arg-header {
	width: 15%;
	margin-right: 20px;
}

.api-arg-header > div {
	color: #333;
	font-weight: bold;
	text-align: right;
}

.api-arg-type {
	color: #aaa;
	font-style: italic;
	font-weight: normal;
}

.api-arg-required {
	margin-top: 5px;
	font-size: 12px;
	color: #ffb300;
}

.api-arg-body {
	width: 80%;
	margin-top: -2px;
	font-size: 16px;
	line-height: 22px;
}

.api-example {
	width: 100%;
	margin-bottom: 30px;
	font-size: 16px;
}

.api-example-header {
	margin-bottom: 5px;
	font-size: 15px;
}

.api-example-body {
	padding: 20px;
	background: #333;
	color: #eee;
	border-radius: 5px
}

.api-example-body.xml {
	width: 530px;
	margin: 0 auto 26px auto;
}

.json-xml-header {
	width: 480px;
	margin: 0 auto;
	color: #999;
}


.api-example-inner-header {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
}

.api-example-inner-body {
	font-size: 15px;
    overflow-wrap: break-word;
}

.api-example-inner-body, span.json-comma {
	color: #aaa;
}

.api-example-spacer {
	height: 30px;
}

.api-response-body {
	margin-left: 5%;
	tab-size: 22px;
	white-space: pre;
	margin-bottom: 10px;
	font-size: 13px;
	line-height: 16px;
}

.json-key {
	color: #d6ff84;
}

.json-space {
	display: inline-block;
	width: 22px;
}

.json-number {
	color: #66d3ef;
}

.json-array .json-number {
	margin-left: 10px;
}

.json-array .json-number.last {
	margin-right: 10px;
}

.json-boolean {
	color: #ff848c
}

/*.json-comma {
	margin-left: 1px;
}*/



@media screen and (min-width: 1100px) {

	.docs-sidebar {
		display: flex;
		flex-direction: column;
	}

	.docs-body {
		width: initial;
		margin-left: 35%;
		max-width: 650px;
	}

	.docs img {
		position: initial;
		margin-left: auto;
	}

	.docs > img {
		display: none;
	}

	.docs-body {
		margin-top: 0;
	}

}



