
._404-header {
	margin-top: 100px;
	font-size: 150px;
	font-family: "Rubik";
	color: #d85057;
	color: #22cca7;
}

._404-body {
	font-size: 20px;
	color: #777;
}

._404-button {
	margin-top: 20px;
	background: #485f70;
	width: 180px;
	height: 50px;
	color: #485f70;
	color: white;
	border: 4px solid #485f70;
	border-radius: 0;
	box-shadow: none;
	font-size: 18px;
	font-weight: 700;
}

._404-button:hover {
	background: #485f70;
	color: white;
}