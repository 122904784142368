
.home {
	font-family: Montserrat;
	min-width: 600px;
	padding-bottom: 20px;
}

.home-banner {
	position: relative;
	width: 100%;
	height: 400px;
	margin-top: -1px;
	background: #2f3338;
	overflow-y: hidden;
}

.home-banner.loaded {
	/*background: #4c031c;*/
}

.home-banner-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url("../../images/home-banner-muted.png");
	background-size: cover;
	background-position: 50% 65%;
	opacity: 0.5;
    filter: grayscale(50%);
}

.banner-content {
	width: 90%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	margin-top: 120px;
	opacity: 0;
}

.banner-content.loaded {
	opacity: 1;
}

.home-banner-right-holder {
	display: flex;
	flex-direction: column;
	position: relative;
	color: #22cca7;
	color: #ffb300;
	color: white;
	width: 47%;
	text-align: left;
	font-size: 18px;
	font-family: Montserrat;
	margin-top: -5px;
}


.home-banner-left {
	position: relative;
	font-size: 28px;
	color: white;
	font-family: "Alfa Slab One";
	letter-spacing: 2px;
	text-align: left;
	margin-right: 30px;
}

.home-banner-left:nth-child(3) {
	font-size: 50px;
}

.banner-logo {
	position: relative;
	width: 100%;
	max-width: 800px;
	
}

.home-banner-secondary {
	position: relative;
	margin-top: 15px;
	font-size: 20px;
	font-weight: bold;
	color: white;
}

.home button {
	position: relative;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	border: 1px solid #5df4d3;
	border-top: 0px solid #5df4d3;
	width: 90%;
	height: 60px;
	margin-top: 15px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	color: white;
	border-radius: 5px;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.3);
}

.home-sub-banner {
	overflow: hidden;
	height: 300px;
	background: #ffb300;
}

.home-sub-banner > h2 {
	margin-top: 40px;
	font-family: "Montserrat";
}

.why-sportsfeed-outer {
	position: relative;
	margin: 0 auto;
}

.why-sportsfeed-holder {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	color: #777777;
}

.why-sportsfeed {
	flex: 1;
	position: relative;
	width: 200px;
	padding-bottom: 22px;
	background: #ad2033;
	color: white;
	padding-top: 20px;
}

.why-sportsfeed:nth-child(1) {
	background: #991a2b;
}

.why-sportsfeed:nth-child(3) {
	background: #c61f36;
}

.why-sportsfeed > i {
	font-size: 3.5em;
	/*color: #ffb300;*/
}

.why-sportsfeed > div {
	display: block;
	position: relative;
	width: 75%;
	margin: 15px auto 0 auto;
	font-size: 1em;
	font-weight: bold;
	line-height: 20px;
}

.home-section {
	display: flex;
	position: relative;
	padding-bottom: 100px;
	/*height: 500px;*/
	border-bottom: 2px solid #eee;
}

.home-section:nth-child(2) {
	border-bottom: none;
}

.home-section-background {
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
}

.home-section-background-left {
	flex: 1;
	height: 100%;
	
}

.home-section-background-right {
	flex: 0;
	height: 100%;
	background-color: #2e4057;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75' viewBox='0 0 120 120'%3E%3Cpolygon fill='%2329394d' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/%3E%3C/svg%3E");
	background-size: 90%;
	background-position: 50% 45%;
	/*opacity: 0;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right::after {
	/*flex: 30% 0;*/
	/*position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background: blue;
	z-index: */
	/*background-image: url("../../images/steph.png");
	background-size: 200%;
	background-position: 50% 45%;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right.yellow {
	background: #F9E900;
	background: #ffb300;
	filter: grayscale(0%);
}

.home-section-foreground {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: calc(100% - 550px);
	width: calc(85% - 175px);
	width: calc(65% + 200px); /* <background left> - <margin left> = 62.5% [70% - 7.5% = 62.5%] + 0.5 * <widget width> [0.5 * 350px]  */
	margin-top: 80px;
	margin-left: 150px;
	margin-left: 5%;
	/*overflow: hidden;
	padding-bottom: 15px;*/
}

.home-section-foreground.lower {
	width: calc(95%);
	margin-top: 40px;

}

.home-section-foreground .tablet {
	/*display: inline-block;
	position: relative;*/
	width: 55%;
	min-width: 55%;
	/*height: 100%;*/
	position: relative;
	/*flex: 0 1 500px;
	width: 500px;
	max-width: 500px;*/
	align-self: flex-start;
	margin-top: 35px;
	/*box-shadow: 0 5px 15px rgba(0,0,0,0.3);*/
	/*width: 500px;
	min-width: 500px*/
	/*flex: 1;*/
	/*height: 400px;*/
}




.home-line-chart-header {
	width: 95%;
	max-width: 1100px;
	margin: 40px auto 10px auto;
	text-align: left;
	font-family: "Montserrat";
	color: #bbb;
	font-size: 20px;
}


.home-line-chart {
	width: 95%;
	max-width: 1000px;
	margin: 0 auto;
	/*border: 1px solid black;*/
	/*padding: 10px;*/
	/*box-shadow: 0 2px 5px rgba(0,0,0,0.2);*/
	padding-bottom: 10px;
}

.home-line-chart canvas {
	margin: 20px;
	max-width: 960px;
	margin-top: 10px;
}


.home-chart-top {
	/*width: calc(100% + 10px);
	position: relative;
	top: -10px;
	left: -10px;*/
	background: black;
	color: white;
	padding: 10px;
}

.home-chart-header {
	text-align: left;
}

.home-chart-header em {
	font-weight: bold;
	font-style: normal;
}

.home-chart-subheader {
	text-align: left;
	font-size: 12px;
	margin-top: 5px;
}

.home-details-holder {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1000px;
	margin: 50px auto;
	padding-bottom: 50px;
	border-bottom: 2px solid #eee;
}

.home-details-holder.first {
	padding-top: 50px;
	border-top: 2px solid #eee;
}

.home-details {
	/*width: 90%;
	max-width: 900px;*/
	/*width: 40%;*/
	/*margin: 0 auto;*/
	font-size: 1.1em;
	color: #485f70;
	text-align: left;
	/*margin-top: 2.5em;
	margin-top: calc(3.5em - 30px);*/
	align-self: center;
	margin-right: 5%;
	/*padding: 120px 0;*/
	/*padding-left: 10%;*/
	font-family: Rubik;
	/*background: blue;*/
	/*color: #444951;*/
	/*border-bottom: 1px solid #eee;*/
}



.details-header {
	margin-bottom: 10px;
	font-size: 1.5em;
	font-weight: 400;
}

.details-body {
	/*font-family: "Montserrat";*/
	line-height: 28px;
}

.home-details button {
	position: relative;
	margin: 20px auto 0 auto;
	background: #d85057;
	background: #c97922;
	background: none;
	width: 180px;
	height: 50px;
	color: #485f70;
	border: none;
	border: 4px solid #485f70;
	/*color: #991a2b;
	border: 4px solid #991a2b;*/
	border-radius: 0;
	box-shadow: none;
	/*border-radius: 3px;
	box-shadow: 0 1px 5px rgba(0,0,0,0.2);*/
	/*background-image: url("../../images/button-wave-2.png");
	background-size: cover;
	background-position: 50% 65%;*/
	
	/*background: #d83c2b;*/
	/*background: #c61f36;*/
	/*background: linear-gradient(to bottom, #d83c2b, #7c1c11);
	border: 2px solid #7f1f15;
	border-top: 1px solid #7f1f15;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.5);*/
}

.home-details button:hover {
	background: #485f70;
	color: white;
}

.widgets-holder {
	/*position: relative;
	width: 90%;
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 100px;
	display: flex;
	justify-content: space-around;
	justify-content: center;*/
	margin-right: 5%;
}

.score-widget {
	width: 250px;
	min-width: 250px;

	margin-left: 150px;
	margin-left: 0;
	/*margin-right: 5px;*/
	background: white;
	font-size: 14px;
	text-align: left;
	box-shadow: 0 5px 15px rgba(0,0,0,0.3);
	max-height: 350px;
}

.score-widget.squeeze {
	display: none;
}

.score-widget-header {
	background: #0b75bc;
	background: #233e51;
	background: linear-gradient(#668499, #233e51);
	background: #2f3338;
	background: black;
	color: white;
	padding: 10px 15px;
	font-family: "Open Sans";
}

/*.score-widget:nth-child(2) .score-widget-header {
	filter: brightness(120%);
}

.score-widget:nth-child(3) .score-widget-header {
	filter: brightness(140%);
}*/

.score-widget-header-date {
	/*font-size: 16px;*/
}

.score-widget-header-venue {
	font-size: 0.7em;
	margin-top: 0.25em;
}

.score-widget-matchup {
	padding: 0 15px 10px 15px;
	/*height: 8em;*/
}

.score-widget-team {
	margin-top: 1.5em;
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.score-widget-team:first-child {
	margin-top: 1em;
}

.score-widget-team-left {
	display: flex;
}

.score-widget-team-left img {
	width: 1.8em;
	height: 1.8em;
	margin-right: 10px;
}

.score-widget-team-name {
	font-size: 1.2em;
	font-weight: bold;
}

.score-widget-team-mascot {
	/*font-size: 14px;*/
	font-size: 0.9em;
	font-weight: bold;
}

.score-widget-team-name span {
	margin-left: 10px;
	font-size: 0.6em;
	color: #999;
}

.score-widget-team-odds {
	font-size: 0.8em;
	margin-top: 0.2em;
	color: #999;
}

.score-widget-team-score {
	margin-top: -6px;
	font-size: 2.5em;
}

.score-widget-total {
	margin-top: 1.5em;
	color: #999;
	text-align: right;
}

.score-widget-footer {
	/*background: #cfeafc;*/
	display: flex;
	justify-content: space-between;
	position: relative;
	border-top: 1px solid #eee;
	color: #999;
	padding: 5px 8px;
	max-height: 50px;
}

.score-widget-footer img {
	height: 32px;
	opacity: 0.5;
}

.score-widget-footer-status {
	margin-right: 5px;
	align-self: center;
}

.home-contact-link {
	display: inline-block;
	margin-top: 10px;
	color: #c97922;
	text-decoration: underline;
	/*cursor: pointer;*/
/*	filter: brightness(80%);*/
}

.home-contact-link:hover {
	filter: brightness(110%);
}

.home-superfooter {
	background: #22cca7;
	background: #9aa9ba;
	color: white;
	padding: 40px;
}

.home-superfooter .home-details {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	color: white;
	text-align: center;
	font-weight: 100;
}	

.home-superfooter .details-body {
	font-size: 20px;
}

.home-superfooter button {
	color: white;
	border: 5px solid white;
}

.home-superfooter button:hover {
	background: white;
	color: #9aa9ba;
}


@media screen and (min-width: 650px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		font-size: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 55px;
	}

	.home-banner-right {
		font-size: 20px;
	}

}


@media screen and (min-width: 800px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

	.home-banner-right {
		font-size: 24px;
	}

	.home-section-background-right {
		flex: 30% 0;
	}

	.home-section-foreground.lower {
		width: 90%;
	}

	.home-section-foreground .tablet {
		align-self: flex-start;
		margin-top: 0;
	}

	.widgets-holder {
		margin-right: 0;
	}

	.score-widget {
		width: 400px;
		min-width: 400px;
		font-size: 18px;
	}

	.score-widget-total {
		margin-top: 2.5em;
	}

}

@media screen and (min-width: 1000px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

}





