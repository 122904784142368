
.modal-holder {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	z-index: 5;
	overflow: scroll;
}

.form-modal {
	position: relative;
	width: 95%;
	max-width: 375px;
	margin: 80px auto 50px auto;
	padding-bottom: 30px;
	background: white;
	border-radius: 3px;
	box-shadow: 0 5px 8px rgba(0,0,0,1);
	overflow-y: scroll;
}

.modal-header {
	position: relative;
	top: 0;
	margin-bottom: 20px;
	padding: 14px 5% 12px 5%;
	background: #627d91;
	background: #f3f3f3;
	text-align: left;
	font-family: "Rubik";
	font-size: 18px;
	font-weight: lighter;
	color: white;
	color: #284256;
}

.x-holder {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.x-line {
	stroke: #284256;
	stroke-width: 5px;
}

.terms-link {
	text-decoration: none;
	color: #3f86b8;
	font-weight: bold;
}

.plan-update {
	width: 88%;
	margin: 25px auto;
	text-align: left;
	line-height: 19px;
	font-size: 14px;
	color: #999;
}

.form-modal form {
	width: 90%;
	margin: 0 auto;
}


.form-row {
	text-align: initial;
	margin-bottom: 17px;
}

.form-row > label {
	margin-bottom: 10px;
	text-align: left;
	font-weight: bold;
	color: #333;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: normal;
	color: #666;
	color: #999;
    opacity: 1;
}

.StripeElement {
	width: calc(100% - 10px);
	height: 18px;
	padding: 10px 5px;
	margin-top: 3px;
	background: white;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid #333;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus, .StripeElement:focus, .form-row select:focus {
	outline: none;
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid, .StripeElement.--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

.form-row select {
	width: 100%;
	height: 38px;
	margin-top: 5px;
	background: white;
	font-size: 15px;
	color: #777;
	border: 1px solid #333;
}

#contact-message {
	height: 200px;
}

#form-error {
	min-height: 14px;
	margin-top: -5px;
	font-size: 12px;
	color: #aa3330;
}

.loader {
	position: absolute;
	left: calc(50% - 15px);
	top: 7px;
	width: 25px;
    height: 25px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top: 3px solid white;
    border-radius: 50%;
    z-index: 1;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.form-modal button {
	height: 45px;
	width: 100%;
	margin-top: 5px;
	background: #c61f36;
	color: white;
	font-size: 18px;
	cursor: pointer;
}

.form-modal .secondary-button {
	height: 38px;
	margin-top: 10px;
	background: #b9c6ce;
	font-size: 15px;
}

.form-modal button:hover {
	box-shadow: 0 2px 8px rgba(0,0,0,0.45);
}

.form-modal button:disabled {
	opacity: 0.6;
}

.forgot-password {
	display: inline-block;
	position: relative;
	top: 12px;
	font-size: 12px;
	font-weight: bold;
	color: #627d91;
	text-decoration: none;
	cursor: pointer;
}

.success-modal {
	position: relative;
	background: white;
	width: 95%;
	max-width: 280px;
	margin: 80px auto 0 auto;
	padding: 15px 0 28px 0;
	border-radius: 3px;
	box-shadow: 0 5px 8px rgba(0,0,0,1)
}

.success-modal i {
	display: block;
	margin: 0 auto 25px auto;
	font-size: 120px;
	font-weight: 100;
	color: #639e70;
	opacity: 0.8;
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-o-transition: 0s;
	transition: 0s;
}

.success-modal i.fade {
	opacity: 0;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.success-modal i.question {
	color: #bbb;
}

.success-modal > div {
	width: 85%;
	margin: 20px auto;
	font-size: 18px;
	color: #777;
}

.success-modal button {
	width: 180px;
	height: 42px;
	background: #627d91;
	cursor: pointer;
}

/*------------- Not currently used -------------*/

.modal-confirm {
	width: 85%;
	margin: 0 auto;
	text-align: left;
	color: #999;
}

.modal-confirm-section > div:first-child {
	margin: 15px 0 3px 0;
	font-weight: bold;
	color: #333;
}

.modal-confirm-details {
	margin-left: 20px;
	margin-top: 3px;
	font-size: 16px;
	font-weight: lighter;
}

.modal-confirm-section span {
	font-size: 14px;
	color: #bbb;
}

.modal-confirm-details.small {
	font-size: 14px;
}

.modal-confirm-terms {
	margin: 5px 0;
}

.modal-confirm-spacer {
	height: 20px;
}

.modal-confirm-terms > label {
	font-size: 11px;
	margin-left: 7px;
}

.modal-confirm-terms > input {
	cursor: pointer;
}




