@import url(//fonts.googleapis.com/css?family=Open+Sans)@import url(//fonts.googleapis.com/css?family=Rubik)
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro)
@import url(//fonts.googleapis.com/css?family=News+Cycle)
@import url(//fonts.googleapis.com/css?family=Montserrat)
@import url(//fonts.googleapis.com/css?family=Permanent+Marker)
@import url(//fonts.googleapis.com/css?family=Anton)
@import url(//fonts.googleapis.com/css?family=Source+Code+Pro)
@import url(//fonts.googleapis.com/css?family=Alfa+Slab+One)



*
html,body {
    width: 100%;
    height: 100%;
    min-width: 600px;
    margin: 0px;
    padding: 0px;
}

body {
  display: fixed;
  position: relative;
  background-attachment: fixed;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

#root {
	min-width: 600px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}


button {
  position: relative;
  height: 50px;
	border: none;
	color: white;
	border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}




.nav-outer {
	/*position: relative;
	z-index: 5;*/
}

.nav-top {
	background: white;
	height: 65px;
}

.nav-top-header {
	margin-right: 5%;
	font-family: Montserrat;
	font-size: 18px;
	font-size: 14px;
	color: #999;
	color: #c97922;
	/*font-weight: bold;*/
	letter-spacing: 1.8px;
}

.navbar {
	/*position: fixed;*/
    width: 100%;
    min-width: 600px;
    background: white;
    background: #2f3338;
    z-index: 4;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

.navbar-inner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 1400px;
	height: 65px;
	margin: 0 auto;
	/*background: white;*/
}

.navbar-inner.links {
	height: 55px;
}

.menu-button {
	height: 30px;
	width: 65px;
	margin-left: 10px;
	padding: 0;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	text-align: center;
	font-size: 13px;	
	box-shadow: none;
	cursor: pointer;
}

.navbar-links {
	padding-right: 10px;
}

.navbar-link {
	align-self: center;
	position: relative;
	margin: 0 8px;
	margin: 0 15px;
	font-size: 14px;
	font-size: 16px;
	font-weight: 500;
	color: #999;
	color: white;
	text-decoration: none;
	cursor: pointer;
}

.nav-logo {
	height: 75%;
    margin: 0 auto 0 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.nav-logo img {
	height: 40px;
    margin-top: 4.5%;
}


@media screen and (min-width: 1000px) {

	.nav-top-header {
		margin-right: 10%;
		font-size: 15px;
		letter-spacing: 3px;
	}


}




.home {
	font-family: Montserrat;
	min-width: 600px;
	padding-bottom: 20px;
}

.home-banner {
	position: relative;
	width: 100%;
	height: 400px;
	margin-top: -1px;
	background: #2f3338;
	overflow-y: hidden;
}

.home-banner.loaded {
	/*background: #4c031c;*/
}

.home-banner-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(/static/media/home-banner-muted.4bfdb257.png);
	background-size: cover;
	background-position: 50% 65%;
	opacity: 0.5;
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
}

.banner-content {
	width: 90%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	margin-top: 120px;
	opacity: 0;
}

.banner-content.loaded {
	opacity: 1;
}

.home-banner-right-holder {
	display: flex;
	flex-direction: column;
	position: relative;
	color: #22cca7;
	color: #ffb300;
	color: white;
	width: 47%;
	text-align: left;
	font-size: 18px;
	font-family: Montserrat;
	margin-top: -5px;
}


.home-banner-left {
	position: relative;
	font-size: 28px;
	color: white;
	font-family: "Alfa Slab One";
	letter-spacing: 2px;
	text-align: left;
	margin-right: 30px;
}

.home-banner-left:nth-child(3) {
	font-size: 50px;
}

.banner-logo {
	position: relative;
	width: 100%;
	max-width: 800px;
	
}

.home-banner-secondary {
	position: relative;
	margin-top: 15px;
	font-size: 20px;
	font-weight: bold;
	color: white;
}

.home button {
	position: relative;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	border: 1px solid #5df4d3;
	border-top: 0px solid #5df4d3;
	width: 90%;
	height: 60px;
	margin-top: 15px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	color: white;
	border-radius: 5px;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.3);
}

.home-sub-banner {
	overflow: hidden;
	height: 300px;
	background: #ffb300;
}

.home-sub-banner > h2 {
	margin-top: 40px;
	font-family: "Montserrat";
}

.why-sportsfeed-outer {
	position: relative;
	margin: 0 auto;
}

.why-sportsfeed-holder {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	color: #777777;
}

.why-sportsfeed {
	flex: 1 1;
	position: relative;
	width: 200px;
	padding-bottom: 22px;
	background: #ad2033;
	color: white;
	padding-top: 20px;
}

.why-sportsfeed:nth-child(1) {
	background: #991a2b;
}

.why-sportsfeed:nth-child(3) {
	background: #c61f36;
}

.why-sportsfeed > i {
	font-size: 3.5em;
	/*color: #ffb300;*/
}

.why-sportsfeed > div {
	display: block;
	position: relative;
	width: 75%;
	margin: 15px auto 0 auto;
	font-size: 1em;
	font-weight: bold;
	line-height: 20px;
}

.home-section {
	display: flex;
	position: relative;
	padding-bottom: 100px;
	/*height: 500px;*/
	border-bottom: 2px solid #eee;
}

.home-section:nth-child(2) {
	border-bottom: none;
}

.home-section-background {
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
}

.home-section-background-left {
	flex: 1 1;
	height: 100%;
	
}

.home-section-background-right {
	flex: 0 1;
	height: 100%;
	background-color: #2e4057;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75' viewBox='0 0 120 120'%3E%3Cpolygon fill='%2329394d' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/%3E%3C/svg%3E");
	background-size: 90%;
	background-position: 50% 45%;
	/*opacity: 0;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right::after {
	/*flex: 30% 0;*/
	/*position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background: blue;
	z-index: */
	/*background-image: url("../../images/steph.png");
	background-size: 200%;
	background-position: 50% 45%;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right.yellow {
	background: #F9E900;
	background: #ffb300;
	-webkit-filter: grayscale(0%);
	        filter: grayscale(0%);
}

.home-section-foreground {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: calc(100% - 550px);
	width: calc(85% - 175px);
	width: calc(65% + 200px); /* <background left> - <margin left> = 62.5% [70% - 7.5% = 62.5%] + 0.5 * <widget width> [0.5 * 350px]  */
	margin-top: 80px;
	margin-left: 150px;
	margin-left: 5%;
	/*overflow: hidden;
	padding-bottom: 15px;*/
}

.home-section-foreground.lower {
	width: calc(95%);
	margin-top: 40px;

}

.home-section-foreground .tablet {
	/*display: inline-block;
	position: relative;*/
	width: 55%;
	min-width: 55%;
	/*height: 100%;*/
	position: relative;
	/*flex: 0 1 500px;
	width: 500px;
	max-width: 500px;*/
	align-self: flex-start;
	margin-top: 35px;
	/*box-shadow: 0 5px 15px rgba(0,0,0,0.3);*/
	/*width: 500px;
	min-width: 500px*/
	/*flex: 1;*/
	/*height: 400px;*/
}




.home-line-chart-header {
	width: 95%;
	max-width: 1100px;
	margin: 40px auto 10px auto;
	text-align: left;
	font-family: "Montserrat";
	color: #bbb;
	font-size: 20px;
}


.home-line-chart {
	width: 95%;
	max-width: 1000px;
	margin: 0 auto;
	/*border: 1px solid black;*/
	/*padding: 10px;*/
	/*box-shadow: 0 2px 5px rgba(0,0,0,0.2);*/
	padding-bottom: 10px;
}

.home-line-chart canvas {
	margin: 20px;
	max-width: 960px;
	margin-top: 10px;
}


.home-chart-top {
	/*width: calc(100% + 10px);
	position: relative;
	top: -10px;
	left: -10px;*/
	background: black;
	color: white;
	padding: 10px;
}

.home-chart-header {
	text-align: left;
}

.home-chart-header em {
	font-weight: bold;
	font-style: normal;
}

.home-chart-subheader {
	text-align: left;
	font-size: 12px;
	margin-top: 5px;
}

.home-details-holder {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1000px;
	margin: 50px auto;
	padding-bottom: 50px;
	border-bottom: 2px solid #eee;
}

.home-details-holder.first {
	padding-top: 50px;
	border-top: 2px solid #eee;
}

.home-details {
	/*width: 90%;
	max-width: 900px;*/
	/*width: 40%;*/
	/*margin: 0 auto;*/
	font-size: 1.1em;
	color: #485f70;
	text-align: left;
	/*margin-top: 2.5em;
	margin-top: calc(3.5em - 30px);*/
	align-self: center;
	margin-right: 5%;
	/*padding: 120px 0;*/
	/*padding-left: 10%;*/
	font-family: Rubik;
	/*background: blue;*/
	/*color: #444951;*/
	/*border-bottom: 1px solid #eee;*/
}



.details-header {
	margin-bottom: 10px;
	font-size: 1.5em;
	font-weight: 400;
}

.details-body {
	/*font-family: "Montserrat";*/
	line-height: 28px;
}

.home-details button {
	position: relative;
	margin: 20px auto 0 auto;
	background: #d85057;
	background: #c97922;
	background: none;
	width: 180px;
	height: 50px;
	color: #485f70;
	border: none;
	border: 4px solid #485f70;
	/*color: #991a2b;
	border: 4px solid #991a2b;*/
	border-radius: 0;
	box-shadow: none;
	/*border-radius: 3px;
	box-shadow: 0 1px 5px rgba(0,0,0,0.2);*/
	/*background-image: url("../../images/button-wave-2.png");
	background-size: cover;
	background-position: 50% 65%;*/
	
	/*background: #d83c2b;*/
	/*background: #c61f36;*/
	/*background: linear-gradient(to bottom, #d83c2b, #7c1c11);
	border: 2px solid #7f1f15;
	border-top: 1px solid #7f1f15;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.5);*/
}

.home-details button:hover {
	background: #485f70;
	color: white;
}

.widgets-holder {
	/*position: relative;
	width: 90%;
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 100px;
	display: flex;
	justify-content: space-around;
	justify-content: center;*/
	margin-right: 5%;
}

.score-widget {
	width: 250px;
	min-width: 250px;

	margin-left: 150px;
	margin-left: 0;
	/*margin-right: 5px;*/
	background: white;
	font-size: 14px;
	text-align: left;
	box-shadow: 0 5px 15px rgba(0,0,0,0.3);
	max-height: 350px;
}

.score-widget.squeeze {
	display: none;
}

.score-widget-header {
	background: #0b75bc;
	background: #233e51;
	background: linear-gradient(#668499, #233e51);
	background: #2f3338;
	background: black;
	color: white;
	padding: 10px 15px;
	font-family: "Open Sans";
}

/*.score-widget:nth-child(2) .score-widget-header {
	filter: brightness(120%);
}

.score-widget:nth-child(3) .score-widget-header {
	filter: brightness(140%);
}*/

.score-widget-header-date {
	/*font-size: 16px;*/
}

.score-widget-header-venue {
	font-size: 0.7em;
	margin-top: 0.25em;
}

.score-widget-matchup {
	padding: 0 15px 10px 15px;
	/*height: 8em;*/
}

.score-widget-team {
	margin-top: 1.5em;
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.score-widget-team:first-child {
	margin-top: 1em;
}

.score-widget-team-left {
	display: flex;
}

.score-widget-team-left img {
	width: 1.8em;
	height: 1.8em;
	margin-right: 10px;
}

.score-widget-team-name {
	font-size: 1.2em;
	font-weight: bold;
}

.score-widget-team-mascot {
	/*font-size: 14px;*/
	font-size: 0.9em;
	font-weight: bold;
}

.score-widget-team-name span {
	margin-left: 10px;
	font-size: 0.6em;
	color: #999;
}

.score-widget-team-odds {
	font-size: 0.8em;
	margin-top: 0.2em;
	color: #999;
}

.score-widget-team-score {
	margin-top: -6px;
	font-size: 2.5em;
}

.score-widget-total {
	margin-top: 1.5em;
	color: #999;
	text-align: right;
}

.score-widget-footer {
	/*background: #cfeafc;*/
	display: flex;
	justify-content: space-between;
	position: relative;
	border-top: 1px solid #eee;
	color: #999;
	padding: 5px 8px;
	max-height: 50px;
}

.score-widget-footer img {
	height: 32px;
	opacity: 0.5;
}

.score-widget-footer-status {
	margin-right: 5px;
	align-self: center;
}

.home-contact-link {
	display: inline-block;
	margin-top: 10px;
	color: #c97922;
	text-decoration: underline;
	/*cursor: pointer;*/
/*	filter: brightness(80%);*/
}

.home-contact-link:hover {
	-webkit-filter: brightness(110%);
	        filter: brightness(110%);
}

.home-superfooter {
	background: #22cca7;
	background: #9aa9ba;
	color: white;
	padding: 40px;
}

.home-superfooter .home-details {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	color: white;
	text-align: center;
	font-weight: 100;
}	

.home-superfooter .details-body {
	font-size: 20px;
}

.home-superfooter button {
	color: white;
	border: 5px solid white;
}

.home-superfooter button:hover {
	background: white;
	color: #9aa9ba;
}


@media screen and (min-width: 650px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		font-size: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 55px;
	}

	.home-banner-right {
		font-size: 20px;
	}

}


@media screen and (min-width: 800px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

	.home-banner-right {
		font-size: 24px;
	}

	.home-section-background-right {
		flex: 30% 0;
	}

	.home-section-foreground.lower {
		width: 90%;
	}

	.home-section-foreground .tablet {
		align-self: flex-start;
		margin-top: 0;
	}

	.widgets-holder {
		margin-right: 0;
	}

	.score-widget {
		width: 400px;
		min-width: 400px;
		font-size: 18px;
	}

	.score-widget-total {
		margin-top: 2.5em;
	}

}

@media screen and (min-width: 1000px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

}







.pricing {
	padding-bottom: 60px;
	position: relative;
	/*font-family: "Rubik";*/
	/* background-image: url(/static/media/home-banner-muted.4bfdb257.png);
    background-size: cover;
    background-position: 50% 65%;
    opacity: 0.5;
    -webkit-filter: grayscale(50%);
    filter: grayscale(50%);*/
}

.pricing-background {
	position: absolute;
	width: 100%;
	/*height: 100%;*/
	top: 0;
	bottom: -105px;
	background-image: url(/static/media/home-banner-muted.4bfdb257.png);
	background-size: cover;
	background-position: 50% 65%;
	opacity: 0.5;
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
}

.pricing-content {
	position: relative;
}

.pricing-header-logo {
	max-width: 320px;
	margin: 50px 0 0 0;
}

.pricing-subheader {
	color: white;
	margin: 8px 0 50px 0;
	font-size: 18px;
	font-weight: bold;
}

.rapid-notice {
	width: 80%;
	max-width: 550px;
	max-width: 800px;
	height: auto;
	margin: 0 auto 30px;
	padding: 35px 40px 10px 40px;
	border-radius: 10px;
	text-align: justify;
}

.rapid-notice-header {
	margin: 8px 0;
	font-size: 22px;
	font-family: "Alfa Slab One";
	font-family: Montserrat;
	color: #991a2b;
	color: #c61f36;
	color: #bbb;
}

.rapid-notice p {
	font-size: 20px;
	line-height: 32px;
	color: #333;
	color: white;
	font-weight: lighter;
	font-family: Montserrat;
}

.rapid-link-holder {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	cursor: pointer;
}

.rapid-link-holder img {
	height: 80px;
}

.rapid-link-holder img:hover {
	opacity: 0.8;
}

.comparison {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
	width: 99%;
	max-width: 950px;
	margin: 0 auto;
	font-size: 15px;
}

.comparison-type {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 450px;
	justify-content: space-between;
	align-self: center;
	box-shadow: 0 2px 30px rgba(0,0,0,0.3);
	background: white;
	margin-bottom: 40px;
	border-radius: 5px;
	overflow: hidden;
	transition: all 0.1s ease-in-out;
	background: #2f3338;
}

/*.comparison-type:hover {
	transform: scale(1.05);
	transition: all 0.1s ease-in-out;
}
*/
.comparison-row {
	display: flex;
	justify-content: center;
	height: 40px;
	font-weight: lighter;
	/*letter-spacing: 0.4px;*/
	line-height: 40px;
	color: #999;
}

.comparison-row .highlight {
	font-weight: bold;
	color: #d85057;
}

.comparison-row i {
	font-size: 20px;
	color: #0ea4f4;
	color: #309cb7;
	color: #22cca7;
}

.comparison-row.alt i {
	color: #c61f36;
}

.comparison-row div {
	font-size: 20px;
	font-weight: bold;
	color: #d85057;
}

.comparison-row em {
	font-style: normal;
	color: #d85057;
	font-weight: bold;
	font-size: 15px;
	margin-left: 7px;
	font-family: "Open Sans";
	position: relative;
	bottom: 2px;
	text-shadow: 1px 0;
}

.comparison-row.alt span {
	color: #ccc;
	color: #666;
	color: #5d5d5d;
}

.comparison-row:nth-child(1) {
	padding-top: 20px;
	font-size: 28px;
	font-weight: lighter;
	letter-spacing: 2px;
	color: #aaa;
	font-family: "Rubik";
}

.comparison-row:nth-child(2) {
	height: 110px;
	font-size: 90px;
	font-weight: bold;
	line-height: 110px;
	color: #333;
	color: white;
	font-family: "Rubik";
}

.comparison-row:nth-child(2) > span {
	font-size: 30px;
	margin: 0 5px;
}

.comparison-row:nth-child(8) {
	border-bottom: none;
}

.comparison-type > div:last-child {
	height: 65px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.comparison-button {
	height: 50px;
	width: 80%;
	max-width: 280px;
	background: #d85057;
	font-size: 1.1em;
	line-height: 45px;
	letter-spacing: 2px;
	color: white;
	border-radius: 100px;
	box-shadow: none;
	cursor: pointer;
}

.comparison-button img {
	position: relative;
	top: -2px;
	height: 30px;
	line-height: 45px;
	vertical-align: middle;
	margin-left: 8px;
	margin-right: -5px;
}

.comparison-button.gray {
	background: #aaa;
	cursor: auto;
	cursor: initial;
}

.comparison-button.gold {
	background: #ffb300;
	color: #333;
	font-weight: bold;
}

.test-key-email {
	margin: 0 auto 20px auto;
	font-size: 14px;
	color: white;
}

.test-key-email em {
	font-weight: bold;
	font-style: normal;
	color: #f9a836;
	cursor: pointer;
}


@media screen and (min-width: 1000px) {

	.pricing {
		padding-bottom: 60px;
	}

	.comparison {
		flex-direction: row;
		font-size: 14px;
	}

	.comparison-type {
		width: 32%;
	}

	.test-key-email {
		margin-top: 20px;
	}

	.comparison-row:nth-child(1) {
		font-size: 24px;
	}

	.comparison-row:nth-child(2) {
		font-size: 70px;
	}
}



.dashboard-outer {
	position: relative;
	width: 100%;	
	padding-bottom: 100px;
	font-family: Montserrat;
}

.dashboard {
	width: 360px;
	/*width: 340px;*/
	margin: 40px auto 0 auto;
	background: white;
	font-size: 15px;
	color: #333;
	box-shadow: 0 5px 8px rgba(0,0,0,0.10);
	border-radius: 5px;
}

.dashboard h2 {
	margin-bottom: 0px;
	padding: 10px;
	background: #627d91;
	background: #2d4d66;
	background: #515b66;
	background: #444951;
	color: white;
	/*color: #627d91;*/
	letter-spacing: 1px;
	/*border-bottom: 1px solid #eee;*/
	font-weight: lighter;
}

.dashboard-item.top {
	/*padding-bottom: 0;*/
}

.dashboard-menu {
	display: flex;
	justify-content: space-around;
	width: 80%;
	height: 35px;
	/*background: #9ca7af;
	background: #afb8bf;*/
	/*margin-bottom: 10px;*/
	margin: 30px auto 0 auto;
	margin-top: 30px;
	color: white;
	color: #515b66;
	border: 1px solid #515b66;
	border-radius: 3px;
	align-items: stretch;
}

.dashboard-menu-item {
	/*width: 33.33%;*/
	flex: 1 1;
	line-height: 35px;
	font-size: 11px;
	/*border-right: 1px solid #afb8bf;*/
	border-right: 1px solid #ad4045;
	background: #d85057;
	background: #c97922;
	color: white;
	cursor: pointer;
}

.dashboard-menu-item:last-child {
	border-right: none;
}

.dashboard-menu-item:hover {
	/*background: #edf0f2;
	background: #f2f3f4;*/
	-webkit-filter: brightness(90%);
	        filter: brightness(90%);
}

.dashboard-inner {
	display: flex;
	justify-content: space-around;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 20px 0;
}

.dashboard-item {
	padding: 15px 0;
}

.dashboard-inner .dashboard-item {
	width: 40%;
	cursor: pointer;
}

.dashboard-inner .dashboard-item:hover {
	background: rgba(0,0,0,0.03)
}

.dashboard-buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px auto;
}

.dashboard .logout {
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	color: #627d91;
	cursor: pointer;
}

.dashboard-item button {
	align-self: center;
	width: 215px;
	height: 32px;
	margin-top: 6px;
	background: #d85057;
	background: #c61f36;
	background: #c97922;
	background: #6a707a;
	font-size: 12px;
	cursor: pointer;
}

.dashboard-item-body.api {
	font-size: 12px;
}

.dashboard-item-body span {
	font-weight: normal;
}

.dashboard-item-header {
	color: #89a1b2;
	color: #ffb300;
	color: #c97922;
	font-size: 18px;
	margin-bottom: 5px;
	font-weight: bold;
}

.dashboard .dashboard-item i {
	/*color: #627d91;*/
	/*color: #89a1b2;*/
	/*color: #b1c2ce;*/
	color: #c3d1db;
	font-size: 80px;
	line-height: normal;
	line-height: initial;
	margin-right: 0;
}

.dashboard .dashboard-inner .dashboard-item i {
	font-size: 70px;
}

.dashboard-item-body {
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
	color: #333;
}

.dashboard-item-body.secondary {
	margin-top: 5px;
	font-size: 12px;
	font-weight: normal;
}

.dashboard-help {
	display: inline-block;
	display: hidden;
	padding: 20px;
	/*font-weight: bold;*/
	color: #d85057;
	color: #627d91;
	cursor: pointer;
}

.logged-out-dashboard {
	margin-top: 100px;
	color: #777;
}

.logged-out-dashboard > div {
	margin-bottom: 20px;
}

.logged-out-dashboard > button {
	width: 150px;
	height: 40px;
	background: #c61f36;
	cursor: pointer;
}



.docs {
	position: relative;
	/*width: 100%;*/
	padding-bottom: 40px;
	padding-top: 20px;
	/*display: flex;
	height: 100%;*/
	width: 100%;
	min-width: 600px;
}

.docs-sidebar {
	display: none;
	position: fixed;
	top: 0;
	/*position: relative;*/
	/*position: absolute;*/
	height: 100%;
	width: 250px;
	width: 30%;
	background: #fafafa;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	font-size: 16px;
	z-index: 10;
}

.docs-sidebar-inner {
	/*display: flex;
	flex-direction: column;*/
	width: 100%;
	position: relative;
	left: 0px;
	width: 180px;
	margin: 20px 20px 0 auto;
	text-align: left;
}


.docs img {
	position: absolute;
	right: 20px;
	/*text-align: right;*/
	height: 40px;
	/*margin: 0 auto;*/
	margin-left: auto;
	cursor: pointer;
}


.sidebar-links-holder {
	margin-left: 15px;
	margin-top: 70px;
}


.sidebar-links-holder > div {
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 0.5px;
}

.docs-sidebar-inner > .docs-sidebar-header {
	position: relative;
	left: -5px;
	margin-top: 75px;
	margin-bottom: 15px;
	font-size: 25px;
	color: #3a506d;
	font-weight: bold;
	-webkit-filter: brightness(170%);
	        filter: brightness(170%);
}

.docs-sidebar ul {
	list-style: none;
	padding-left: 15px;
	margin: 0;
	font-weight: normal;
	opacity: 0.85;
	letter-spacing: 0.5px;
}

.docs-sidebar li {
	list-style: none;
	margin-top: 5px;
	font-weight: 100;
}

.docs-link {
	text-decoration: none;
	color: #37444f;
}

.docs-body {
	position: relative;
	/*left: 10px;*/
	width: 95%;
	min-width: 550px;
	top: 0;
	margin: 0 auto;
	margin-top: 50px;
	padding-bottom: 30px;
	text-align: left;
	font-size: 14px;	
}

.anchor {
    display: block;
    position: relative;
    top: -7px;
    visibility: hidden;
}

#initial.anchor {
	top: -100px;
}

#endpoints.anchor {
	top: -15px;
}

.initial-setup {
	margin-bottom: 30px;
	margin-top: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.docs-api-key {
	display: flex;
	flex-direction: column;
	width: 530px;
	margin: 0 auto;
}

.generic-dark-space {
	padding: 15px;
	background: #333;
	text-align: center;
	font-size: 15px;
	color: #aaa;
	border-radius: 5px;
}

.header-attribute {
	margin-right: 10px;
	color: #c1ef65;
	color: #d6ff84;
}

.docs-show-api-key {
	align-self: flex-end;
	width: 70px;
	height: 25px;
	margin-top: 5px;
	background: #0ea4f4;
	font-size: 11px;
	font-weight: bold;
	line-height: 24px;
	box-shadow: none;
}

.docs-show-api-key:disabled {
	visibility: hidden;
}

.docs-body-inner-header {
	margin-bottom: 40px;
}

.docs-body p {
	/*font-family: "Source Sans Pro";*/
	font-size: 15px;
	font-weight: normal;
	line-height: 1.3;
}

.download-link {
	color: #4e84c9;
	cursor: pointer;
}

.docs-body p em {
	padding: 1px 4px;
	background: #f2f2f2;
	font-style: normal;
	color: #aa3330;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.docs-body p em.endpoint {
	color: #064568;
	font-weight: bold;
}

.league-list {
	display: flex;
	justify-content: space-between;
	position: relative;
	left: 0;
	width: 65%;
	margin: 0 auto 30px auto;
	padding: 15px 25px;
	background: #f2f2f2;
	border: 1px solid #ddd;
	border-radius: 5px;
}

.league-list > div {
	list-style: none;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

.api-section {
	padding: 10px 0;
	font-size: 25px;
	color: #999;
	border-top: 1px solid #ccc;
}

.api-section-overview {
	margin: 20px 0 30px 0;
	padding: 15px;
	background: #e8eef7;
	font-size: 15px;
	color: #000;
	border-radius: 5px
}

.api-section-restricted {
	margin-top: 12px;
	font-style: italic;
	color: #617daa;
	color: #7b8dad;
	margin-bottom: -2px;
}

.api-arg {
	display: flex;
	width: 100%;
	margin: 20px 0 30px 0;
	font-size: 16px;
}

.api-arg-header {
	width: 15%;
	margin-right: 20px;
}

.api-arg-header > div {
	color: #333;
	font-weight: bold;
	text-align: right;
}

.api-arg-type {
	color: #aaa;
	font-style: italic;
	font-weight: normal;
}

.api-arg-required {
	margin-top: 5px;
	font-size: 12px;
	color: #ffb300;
}

.api-arg-body {
	width: 80%;
	margin-top: -2px;
	font-size: 16px;
	line-height: 22px;
}

.api-example {
	width: 100%;
	margin-bottom: 30px;
	font-size: 16px;
}

.api-example-header {
	margin-bottom: 5px;
	font-size: 15px;
}

.api-example-body {
	padding: 20px;
	background: #333;
	color: #eee;
	border-radius: 5px
}

.api-example-body.xml {
	width: 530px;
	margin: 0 auto 26px auto;
}

.json-xml-header {
	width: 480px;
	margin: 0 auto;
	color: #999;
}


.api-example-inner-header {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
}

.api-example-inner-body {
	font-size: 15px;
    overflow-wrap: break-word;
}

.api-example-inner-body, span.json-comma {
	color: #aaa;
}

.api-example-spacer {
	height: 30px;
}

.api-response-body {
	margin-left: 5%;
	-moz-tab-size: 22px;
	     tab-size: 22px;
	white-space: pre;
	margin-bottom: 10px;
	font-size: 13px;
	line-height: 16px;
}

.json-key {
	color: #d6ff84;
}

.json-space {
	display: inline-block;
	width: 22px;
}

.json-number {
	color: #66d3ef;
}

.json-array .json-number {
	margin-left: 10px;
}

.json-array .json-number.last {
	margin-right: 10px;
}

.json-boolean {
	color: #ff848c
}

/*.json-comma {
	margin-left: 1px;
}*/



@media screen and (min-width: 1100px) {

	.docs-sidebar {
		display: flex;
		flex-direction: column;
	}

	.docs-body {
		width: auto;
		width: initial;
		margin-left: 35%;
		max-width: 650px;
	}

	.docs img {
		position: static;
		position: initial;
		margin-left: auto;
	}

	.docs > img {
		display: none;
	}

	.docs-body {
		margin-top: 0;
	}

}





.modal-holder {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	z-index: 5;
	overflow: scroll;
}

.form-modal {
	position: relative;
	width: 95%;
	max-width: 375px;
	margin: 80px auto 50px auto;
	padding-bottom: 30px;
	background: white;
	border-radius: 3px;
	box-shadow: 0 5px 8px rgba(0,0,0,1);
	overflow-y: scroll;
}

.modal-header {
	position: relative;
	top: 0;
	margin-bottom: 20px;
	padding: 14px 5% 12px 5%;
	background: #627d91;
	background: #f3f3f3;
	text-align: left;
	font-family: "Rubik";
	font-size: 18px;
	font-weight: lighter;
	color: white;
	color: #284256;
}

.x-holder {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.x-line {
	stroke: #284256;
	stroke-width: 5px;
}

.terms-link {
	text-decoration: none;
	color: #3f86b8;
	font-weight: bold;
}

.plan-update {
	width: 88%;
	margin: 25px auto;
	text-align: left;
	line-height: 19px;
	font-size: 14px;
	color: #999;
}

.form-modal form {
	width: 90%;
	margin: 0 auto;
}


.form-row {
	text-align: left;
	text-align: initial;
	margin-bottom: 17px;
}

.form-row > label {
	margin-bottom: 10px;
	text-align: left;
	font-weight: bold;
	color: #333;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: normal;
	color: #666;
	color: #999;
    opacity: 1;
}

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: normal;
	color: #666;
	color: #999;
    opacity: 1;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: normal;
	color: #666;
	color: #999;
    opacity: 1;
}

.StripeElement {
	width: calc(100% - 10px);
	height: 18px;
	padding: 10px 5px;
	margin-top: 3px;
	background: white;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid #333;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus, .StripeElement:focus, .form-row select:focus {
	outline: none;
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid, .StripeElement.\--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

.form-row select {
	width: 100%;
	height: 38px;
	margin-top: 5px;
	background: white;
	font-size: 15px;
	color: #777;
	border: 1px solid #333;
}

#contact-message {
	height: 200px;
}

#form-error {
	min-height: 14px;
	margin-top: -5px;
	font-size: 12px;
	color: #aa3330;
}

.loader {
	position: absolute;
	left: calc(50% - 15px);
	top: 7px;
	width: 25px;
    height: 25px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top: 3px solid white;
    border-radius: 50%;
    z-index: 1;
    -webkit-animation: spin 0.8s linear infinite;
            animation: spin 0.8s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.form-modal button {
	height: 45px;
	width: 100%;
	margin-top: 5px;
	background: #c61f36;
	color: white;
	font-size: 18px;
	cursor: pointer;
}

.form-modal .secondary-button {
	height: 38px;
	margin-top: 10px;
	background: #b9c6ce;
	font-size: 15px;
}

.form-modal button:hover {
	box-shadow: 0 2px 8px rgba(0,0,0,0.45);
}

.form-modal button:disabled {
	opacity: 0.6;
}

.forgot-password {
	display: inline-block;
	position: relative;
	top: 12px;
	font-size: 12px;
	font-weight: bold;
	color: #627d91;
	text-decoration: none;
	cursor: pointer;
}

.success-modal {
	position: relative;
	background: white;
	width: 95%;
	max-width: 280px;
	margin: 80px auto 0 auto;
	padding: 15px 0 28px 0;
	border-radius: 3px;
	box-shadow: 0 5px 8px rgba(0,0,0,1)
}

.success-modal i {
	display: block;
	margin: 0 auto 25px auto;
	font-size: 120px;
	font-weight: 100;
	color: #639e70;
	opacity: 0.8;
	transition: 0s;
}

.success-modal i.fade {
	opacity: 0;
	transition: 0.5s;
}

.success-modal i.question {
	color: #bbb;
}

.success-modal > div {
	width: 85%;
	margin: 20px auto;
	font-size: 18px;
	color: #777;
}

.success-modal button {
	width: 180px;
	height: 42px;
	background: #627d91;
	cursor: pointer;
}

/*------------- Not currently used -------------*/

.modal-confirm {
	width: 85%;
	margin: 0 auto;
	text-align: left;
	color: #999;
}

.modal-confirm-section > div:first-child {
	margin: 15px 0 3px 0;
	font-weight: bold;
	color: #333;
}

.modal-confirm-details {
	margin-left: 20px;
	margin-top: 3px;
	font-size: 16px;
	font-weight: lighter;
}

.modal-confirm-section span {
	font-size: 14px;
	color: #bbb;
}

.modal-confirm-details.small {
	font-size: 14px;
}

.modal-confirm-terms {
	margin: 5px 0;
}

.modal-confirm-spacer {
	height: 20px;
}

.modal-confirm-terms > label {
	font-size: 11px;
	margin-left: 7px;
}

.modal-confirm-terms > input {
	cursor: pointer;
}






.footer {
	height: 100px;
	position: relative;
	z-index: 4;
}

.footer-inner {
	position: relative;
	max-width: 220px;
	margin: 5px auto 0 auto;
	padding: 0 2%;
	font-size: 16px;
}

.footer-link-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	position: relative;
	margin: 0;
    padding: 10px 0 0 0;
}

.footer-link {
	position: relative;
    padding-top: 5px;
    color: #bbb;
    cursor: pointer;
}

.pricing-footer .footer-link {
	color: white;
}

.footer-link:hover {
    color: #ddd;
}






.faq-holder {
	width: 92%;
	max-width: 800px;
	margin: 40px auto 0 auto;
	padding-bottom: 30px;
	text-align: left;
}

.faq-group-header {
	margin-bottom: 28px;
	font-size: 28px;
	color: #f46065;
	color: #c97922;
	font-weight: 100;
}

.faq-section {
	position: relative;
    width: 97%;
    margin-top: 10px;
    padding: 0 3%;
    font-size: 16px;
    color: #333;
    color: #444951;
    border-radius: 10px;
}

.faq-section-left {
	position: absolute;
	left: 0;
	top: 10px;
	height: 60px;
	border-left: 3px solid #ffb300;
	border-left: 3px solid #22cca7;
	border-left: 3px solid #c97922;
}

.faq-section-header {
	display: flex;
	align-content: center;
	padding: 10px 0;
}

.faq-section-title {
	font-size: 20px;
	/*font-weight: bold;*/
}

.faq-section-body {
	width: 100%;
	padding-bottom: 30px;
	font-size: 15px;
	font-weight: 100;
	line-height: 22px;
	margin-top: 5px;
}

.faq-section-body ul {
	margin-bottom: 0;
}

.faq-section-body a {
	text-decoration: none;
	font-weight: bold;
	color: #960939;
	cursor: pointer;
}

.faq-other-questions {
	margin-top: 20px;
	text-align: center;
	font-size: 20px;
	color: #999;
}

.faq-other-questions i {
	margin-top: 10px;
	font-size: 40px;
	color: #4b79c1;
	cursor: pointer;
}

.divider {
	height: 1px;
	width: 100%;
	background: #ddd;
	margin-top: 80px;
}





.terms-holder {
	position: relative;
	width: 92%;
	max-width: 800px;
	margin: 40px auto 0 auto;
	padding-bottom: 50px;
	text-align: left;
	font-size: 15px;
	font-weight: lighter;
	color: #333;
	color: #444951;
}

.terms-holder > hr {
	border-top: 1px solid #eee;
}

.terms-disclaimer {
	/*width: calc(102% - 30px);
	position: relative;
	left: -1%;*/
	margin: 25px 0 20px 0;
	padding: 10px 15px;
	background: #e8eef7;
	font-size: 15px;
/*	color: #000;*/
	border-radius: 5px
}

.terms-disclaimer > .terms-subheader {
	padding-top: 5px;
}

.terms-header {
	margin-bottom: 28px;
	color: #f46065;
	color: #c97922;
	font-size: 28px;
}

.terms-type-header {
	margin-bottom: 28px;
	color: #f46065;
	font-size: 18px;
}

.terms-subheader {
	padding: 20px 0 0 0;
	font-size: 20px;
	font-weight: normal;
	color: #000;
	color: #444951;
}

.terms-holder p {
	line-height: 22px;
}

.terms-link {
	font-weight: bold;
	font-weight: normal;
	color: #960939;
	color: #0099e5;
	color: #22cca7;
	text-decoration: underline;
	cursor: pointer;
}

.rapid-disclaimer {
	font-size: 14px;
	color: #aaa;
	display: block;
	margin-top: 40px;
}
/*div.stripe-link {
	margin: 10px 0 25px 0;
}*/




._404-header {
	margin-top: 100px;
	font-size: 150px;
	font-family: "Rubik";
	color: #d85057;
	color: #22cca7;
}

._404-body {
	font-size: 20px;
	color: #777;
}

._404-button {
	margin-top: 20px;
	background: #485f70;
	width: 180px;
	height: 50px;
	color: #485f70;
	color: white;
	border: 4px solid #485f70;
	border-radius: 0;
	box-shadow: none;
	font-size: 18px;
	font-weight: 700;
}

._404-button:hover {
	background: #485f70;
	color: white;
}

#react-container {
  width: 100%;
}

.page-holder {
  position: relative;
  width: 100%;
  /*padding-top: 65px;
  padding-top: 120px;*/
  min-height: calc(100vh - 165px);
  z-index: 2;
  
}

#react-container.dash {
  background: #f3f3f3;
}

#react-container.pric {
	background: #a64643;
	background-image: linear-gradient(to top right, #301023, #6a273a, #a64643, #d87140, #f9a836);
  background-image: linear-gradient(to right top, #3e4449, #5c6369, #7b838a, #9ca5ad, #bec8d1);
  background-image: linear-gradient(to right top, #313e47, #4a5862, #64737e, #7f8f9c, #9cacba);
  background: #2f3338;
  background: #535c68;
  /*background-image: linear-gradient(to right top, #2f3438, #41474c, #545a60, #686f76, #7c848c);*/
 /* background-image: url("/images/home-banner-muted.png");
  background-size: cover;
  background-position: 50% 65%;*/

 /* background-image: url(/static/media/home-banner-muted.4bfdb257.png);
  background-size: cover;
  background-position: 50% 65%;
  opacity: 1;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);*/
  
}






