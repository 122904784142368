
.dashboard-outer {
	position: relative;
	width: 100%;	
	padding-bottom: 100px;
	font-family: Montserrat;
}

.dashboard {
	width: 360px;
	/*width: 340px;*/
	margin: 40px auto 0 auto;
	background: white;
	font-size: 15px;
	color: #333;
	box-shadow: 0 5px 8px rgba(0,0,0,0.10);
	border-radius: 5px;
}

.dashboard h2 {
	margin-bottom: 0px;
	padding: 10px;
	background: #627d91;
	background: #2d4d66;
	background: #515b66;
	background: #444951;
	color: white;
	/*color: #627d91;*/
	letter-spacing: 1px;
	/*border-bottom: 1px solid #eee;*/
	font-weight: lighter;
}

.dashboard-item.top {
	/*padding-bottom: 0;*/
}

.dashboard-menu {
	display: flex;
	justify-content: space-around;
	width: 80%;
	height: 35px;
	/*background: #9ca7af;
	background: #afb8bf;*/
	/*margin-bottom: 10px;*/
	margin: 30px auto 0 auto;
	margin-top: 30px;
	color: white;
	color: #515b66;
	border: 1px solid #515b66;
	border-radius: 3px;
	align-items: stretch;
}

.dashboard-menu-item {
	/*width: 33.33%;*/
	flex: 1;
	line-height: 35px;
	font-size: 11px;
	/*border-right: 1px solid #afb8bf;*/
	border-right: 1px solid #ad4045;
	background: #d85057;
	background: #c97922;
	color: white;
	cursor: pointer;
}

.dashboard-menu-item:last-child {
	border-right: none;
}

.dashboard-menu-item:hover {
	/*background: #edf0f2;
	background: #f2f3f4;*/
	filter: brightness(90%);
}

.dashboard-inner {
	display: flex;
	justify-content: space-around;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 20px 0;
}

.dashboard-item {
	padding: 15px 0;
}

.dashboard-inner .dashboard-item {
	width: 40%;
	cursor: pointer;
}

.dashboard-inner .dashboard-item:hover {
	background: rgba(0,0,0,0.03)
}

.dashboard-buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px auto;
}

.dashboard .logout {
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	color: #627d91;
	cursor: pointer;
}

.dashboard-item button {
	align-self: center;
	width: 215px;
	height: 32px;
	margin-top: 6px;
	background: #d85057;
	background: #c61f36;
	background: #c97922;
	background: #6a707a;
	font-size: 12px;
	cursor: pointer;
}

.dashboard-item-body.api {
	font-size: 12px;
}

.dashboard-item-body span {
	font-weight: normal;
}

.dashboard-item-header {
	color: #89a1b2;
	color: #ffb300;
	color: #c97922;
	font-size: 18px;
	margin-bottom: 5px;
	font-weight: bold;
}

.dashboard .dashboard-item i {
	/*color: #627d91;*/
	/*color: #89a1b2;*/
	/*color: #b1c2ce;*/
	color: #c3d1db;
	font-size: 80px;
	line-height: initial;
	margin-right: 0;
}

.dashboard .dashboard-inner .dashboard-item i {
	font-size: 70px;
}

.dashboard-item-body {
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
	color: #333;
}

.dashboard-item-body.secondary {
	margin-top: 5px;
	font-size: 12px;
	font-weight: normal;
}

.dashboard-help {
	display: inline-block;
	display: hidden;
	padding: 20px;
	/*font-weight: bold;*/
	color: #d85057;
	color: #627d91;
	cursor: pointer;
}

.logged-out-dashboard {
	margin-top: 100px;
	color: #777;
}

.logged-out-dashboard > div {
	margin-bottom: 20px;
}

.logged-out-dashboard > button {
	width: 150px;
	height: 40px;
	background: #c61f36;
	cursor: pointer;
}

